import { ChangeDetectionStrategy, Component, computed, inject, input, OnDestroy, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonRefresher, IonRefresherContent, RefresherEventDetail, AlertController, NavController, ModalController, LoadingController } from '@ionic/angular/standalone';
import { IonRefresherCustomEvent } from '@ionic/core';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { PromotionRowComponent } from 'src/app/components/elements/promotion-row/promotion-row.component';
import { PromotionCustomer } from '@chemist2u/types-client/C2U/ParseObjects';
import { ErrorService } from 'src/app/services/error.service';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { InputComponent } from 'src/app/components/primitives/input/input.component';
import { CloudFunctionsService } from 'src/app/services/cloud.functions.service';
import { StateService } from 'src/app/services/state.service';
import { PullService } from 'src/app/services/pull.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { Subscription } from 'rxjs';
import { PushService } from 'src/app/services/push.service';

@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.page.html',
  styleUrls: ['./coupon-list.page.scss'],
  standalone: true,
  imports: [PromotionRowComponent, InputComponent, ButtonComponent, SimpleButtonComponent, TitleComponent, HeaderComponent, IonRefresher, IonRefresherContent, IonContent, IonHeader, CommonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CouponListPage implements OnDestroy {
  private $cloud = inject(CloudFunctionsService);
  private $state = inject(StateService);
  private $pull = inject(PullService);
  private $push = inject(PushService);
  private $error = inject(ErrorService);
  private $amplitude = inject(AmplitudeService);

  private alertController = inject(AlertController);
  private navController = inject(NavController);
  private loadingController = inject(LoadingController);
  private modalController = inject(ModalController);
  
  private promotions =  signal<PromotionCustomer[]>([]);
  private promotionSub: Subscription | undefined;

  constructor() {
    if(this.pageState() === "page") {
      this.$amplitude.track('NAVIGATE_PROMOTIONS');
    }
    if(this.pageState() === "modal") {
      this.$amplitude.track('MODAL_SHOW', { modal: 'coupon-list' });
    }
    this.promotions.set(this.$state.bPromotionCustomer.getValue() || []);
    this.setCurrentPastPromotions(this.promotions());
    this.promotionSub = this.$state.bPromotionCustomer.subscribe((promotions) => {
      this.promotions.set(promotions);
      this.setCurrentPastPromotions(promotions);
    });
  }

  ngOnDestroy(): void {
    if(this.promotionSub) {
      this.promotionSub.unsubscribe();
    }
  }

  public inputValue = signal<string>("");

  public pageState = input<"modal" | "page">("page");
  public canSelect = computed<boolean>(() => {
    return this.pageState() == "modal";
  });
  public canClear = computed<boolean>(() => {
    return this.pageState() == "page";
  });

  public currentPromotions = signal<PromotionCustomer[]>([]);
  public pastPromotions = signal<PromotionCustomer[]>([]);

  private setCurrentPastPromotions(promotions: PromotionCustomer[]) {
    if(promotions) {
      this.currentPromotions.set(this.filterPromotions('active', promotions));
      this.pastPromotions.set(this.filterPromotions('expired', promotions));
    }
  }

  public checkPromotionDisabled = computed<boolean>(() => {
    return this.inputValue().length === 0;
  });


  async refreshPromotions() {
    try {
      await this.$pull.customerPromotions();
    } catch (error: any) {
      this.$error.handleParseError(error);
    }
  }

  async doRefresh(event: IonRefresherCustomEvent<RefresherEventDetail>) {
    await this.refreshPromotions();
    event.target.complete();
  }

  back() {
    if (this.pageState() === "page") {
      this.navController.navigateBack('/payments-coupons');
      this.$amplitude.track('GENERIC_BACK');
    }

    if (this.pageState() === "modal") {
      this.dismiss();
    }
  }

  async dismiss(data?: any) {
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss(data);
      this.$amplitude.track('MODAL_DISMISS', { modal: 'coupon-list' });
    }
  }

  selectPromotion(promotion: PromotionCustomer) {
    if (this.pageState() === "modal") {
      this.dismiss(promotion);
    }
  }

  async clearPromotion(promotion: PromotionCustomer) {
    const alert = await this.alertController.create({
      header: 'Remove Coupon',
      message: "Would you like to remove coupon from saved coupons ?",
      buttons: [
        { text: 'No', role: 'cancel' },
        { text: 'Yes', role: 'confirm' }
      ]
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();

    if (role === "confirm") {
      const loader = await this.loadingController.create();
      await loader.present();
      await this.$push.destroyPromotion(promotion);
      this.$amplitude.track('PROMOTION_REMOVE_SUCCESS');
      await loader.dismiss();
    }
  }

  filterPromotions(type: "active" | "expired", promotions: PromotionCustomer[]): PromotionCustomer[] {
    const filtered: PromotionCustomer[] = [];
    for (const promotion of promotions) {
      const now = new Date();
      if (promotion.promotionObject && promotion.promotionObject.expiry) {
        const expiry = promotion.promotionObject.expiry;
        const expiryDate = expiry instanceof Date ? expiry : new Date(expiry?.iso)
        if ((type == "active" && expiryDate >= now) || (type == "expired" && expiryDate < now)) {
          filtered.push(promotion);
        }
      }
    }
    return filtered;
  }

  async checkPromotion() {
    const loader = await this.loadingController.create();
    try {
      loader.present();
      const promotion = await this.$cloud.checkPromotion(this.inputValue());
      if (!promotion) {
        await this.$error.showToast({
          message: "Invalid promotion code",
          header: "Error",
          position: "top",
          duration: 2000,
        });
        this.$amplitude.track('PROMOTION_NOT_FOUND');
        return;
      }
      loader.dismiss();
      if (this.canSelect() && this.pageState() === "modal") {
        this.dismiss(promotion);
      }
      this.$amplitude.track('PROMOTION_ADD_SUCCESS');
    } catch (e: any) {
      await this.$error.showToast({
        message: e?.message || "Error checking promotion",
        header: "Error",
        position: "top",
        duration: 2000,
      });
      this.$error.handleParseError(e);
      loader.dismiss();
      this.$amplitude.track('PROMOTION_ADD_FAIL');
    } finally {
      this.inputValue.set("");
      loader.dismiss();
    }
  }

}