import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, ModalController, LoadingController, IonFooter } from '@ionic/angular/standalone';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { SectionDescriptionComponent } from 'src/app/components/elements/section-description/section-description.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { LoginWrapperComponent } from 'src/app/components/elements/login-wrapper/login-wrapper.component';
import { InputComponent } from 'src/app/components/primitives/input/input.component';
import { ErrorService } from 'src/app/services/error.service';
import { FetchService } from 'src/app/services/fetch.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
  standalone: true,
  imports: [IonFooter, IonContent, IonHeader, CommonModule, FormsModule, HeaderComponent, FooterComponent, SimpleButtonComponent, TitleComponent, SectionDescriptionComponent, ButtonComponent, LoginWrapperComponent, InputComponent]
})
export class ResetPasswordPage implements OnInit {
  private $error = inject(ErrorService);
  private $fetch = inject(FetchService);
  private $amplitude = inject(AmplitudeService);
  private modalController = inject(ModalController);
  private loadingController = inject(LoadingController);

  public emailAddress = signal('');

  public isEmailValid = computed(() => {
    return this.validateEmail(this.emailAddress());
  });

  ngOnInit() {
    this.$amplitude.track('MODAL_SHOW_RESET_PASSWORD');
  }

  async close() {
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss();
      this.$amplitude.track('MODAL_DISMISS_RESET_PASSWORD');
    }
  }

  private validateEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@(?:(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})$/;
    return emailPattern.test(email);
  }

  async resetPassword() {
    const email = this.emailAddress().trim().toLowerCase();
    const loader = await this.loadingController.create();

    try {
      await loader.present();
      await this.$fetch.resetPassword(email);
      this.emailAddress.set('');
      this.$amplitude.track('RESET_PASSWORD_SEND_SUCCESS');
    } catch(error: any) {
      this.$amplitude.track('RESET_PASSWORD_SEND_FAIL');
      console.error("Error presenting loader", error);
      this.$error.showToast({
        message: "Error presenting loader",
        color: "danger",
        duration: 3000,
        position: 'bottom',
        animated: true,
        swipeGesture: "vertical"
      })
    } finally {
      await loader.dismiss();
      await this.$error.showToast({
        message: "An email has been sent to your email address with instructions on how to reset your password.",
        color: "success",
        duration: 3000,
        position: 'bottom',
        animated: true,
        swipeGesture: "vertical"
      });
      this.close();
    }
  }

  onEmailChange(email: string | number | Date) {
    this.emailAddress.set(email as string);
  }
}
