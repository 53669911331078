import { CurrencyPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, computed, input, output, signal } from '@angular/core';
import { IonIcon } from "@ionic/angular/standalone";
import { addIcons } from 'ionicons';
import { arrowForwardOutline } from 'ionicons/icons';
type ColorType = "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "dark" | "medium" | "light" | "white";
type SizeType = "small" | "default" | "large";

interface ButtonState {
  bgColor: ColorType;
  color: ColorType;
  size: SizeType;
  iconRight?: string;
  iconLeft?: string;
  bold: boolean;
  label: string;
  customIcon: boolean;
  disabled: boolean;
  textRight?: string;
  isPrice?: boolean;
  fitContent?: boolean;
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [IonIcon,NgClass,CurrencyPipe],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnInit {

  ngOnInit(): void {
    addIcons({ arrowForwardOutline });
  }

  state = signal<ButtonState>({
    bgColor: "primary",
    color: "white",
    size: "default",
    bold: false,
    label: "Button",
    customIcon: false,
    disabled: false,
    isPrice: false,
    fitContent: false,
  });

  @Input() set bgColor(value: ColorType) {
    this.state.update(s => ({ ...s, bgColor: value }));
  }
  @Input() set color(value: ColorType) {
    this.state.update(s => ({ ...s, color: value }));
  }
  @Input() set size(value: SizeType) {
    this.state.update(s => ({ ...s, size: value }));
  }
  @Input() set iconRight(value: string | undefined) {
    this.state.update(s => ({ ...s, iconRight: value }));
  }
  @Input() set iconLeft(value: string | undefined) {
    this.state.update(s => ({ ...s, iconLeft: value }));
  }
  @Input() set bold(value: boolean) {
    this.state.update(s => ({ ...s, bold: value }));
  }
  @Input() set label(value: string) {
    this.state.update(s => ({ ...s, label: value }));
  }
  @Input() set customIcon(value: boolean) {
    this.state.update(s => ({ ...s, customIcon: value }));
  }
  @Input() set disabled(value: boolean) {
    this.state.update(s => ({ ...s, disabled: value }));
  }
  @Input() set fitContent(value: boolean) {
    this.state.update(s => ({ ...s, fitContent: value }));
  }

  public textRight = input<string | undefined>(undefined);

  public textRightDisplay = computed(() => {
    return this.textRight();
  });

  @Input() set isPrice(value: boolean) {
    this.state.update(s => ({ ...s, isPrice: value }));
  };

  public clicked = output<void>();

  buttonClasses = computed(() => {
    const { bgColor, color, size, bold, disabled, fitContent } = this.state();
    return {
      [`btn-bg-${bgColor}`]: true,
      [`btn-color-${color}`]: true,
      [`btn-${size}`]: true,
      'btn-bold': bold,
      'btn-disabled': disabled,
      'btn-fit-content': fitContent,
    };
  });

  iconClasses(position: 'left' | 'right') {
    const { customIcon, iconLeft, iconRight } = this.state();
    const icon = position === 'left' ? iconLeft : iconRight;
    return {
      'icon': true,
      [position]: true,
      [icon!]: !customIcon && icon,
      'custom-icon': customIcon && icon
    };
  }

  handleClick() {
    if (!this.state().disabled) {
      this.clicked.emit();
    }
  }

}