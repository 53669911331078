<div class="container" [class.visible]="!cardToUse()">
  <div class="payment">
    @if (isLoading()) {
      <div class="loading">
        <p class="label">Loading payment form...</p>
      </div>
    }
    <form id="payment-form" [class.visible]="!isLoading()">
      <div #cardContainer id="paymentWrapper-cardContainer"></div>
    </form>
  </div>
  <app-simple-button color="tint" icon="add-outline" [border]="true" label="Save Card" (click)="addCard()" />
</div>

<div class="container" [class.visible]="cardToUse()">
  <app-payment-row [nopadding]="true" (click)="changeCard()" [card]="cardToUse()" mode="select" />
</div>
