<div class="methods">
  <app-title [title]="title()" />
  <div class="list">
    @if(onDemandMethod()) {
    <app-delivery-method-option [canReschedule]="advanced()" [canSetATL]="advanced()" [fullFillmentMethod]="onDemandMethod()" [methodSelected]="onDemandSelected()"
      (selectEvent)="methodSelected($event)" [location]="location()" (atlEvent)="setAtl($event)"  (updateShiftForOnDemandEvent)="updateShiftForOnDemand($event)" />
    }

    @if(standardMethod()) {
    <app-delivery-method-option  [canSetATL]="advanced()" [fullFillmentMethod]="standardMethod()"
      [methodSelected]="standardSelected()" [location]="location()" (atlEvent)="setAtl($event)"  (selectEvent)="methodSelected($event)" />
    }

    @if(clickAndCollectMethod()) {
    <app-delivery-method-option [pharmacyAddress]="pharmacyAddress()" [customerAddress]="customerAddress()" [fullFillmentMethod]="clickAndCollectMethod()" (selectEvent)="methodSelected($event)" [isSelectable]="true"
       [methodSelected]="clickAndCollectSelected()" />
    }

    @if(postalMethod()) {
      <app-delivery-method-option [fullFillmentMethod]="postalMethod()" (selectEvent)="methodSelected($event)" [methodSelected]="postalSelected()" />
    }


  </div>
</div>