<ion-header>
  <app-header [border]="true" background="faded" [extra]="true" background="faded">
    <app-simple-button (click)="closeCart()" color="main" size="large" slot="start" icon="close" />
    <app-title slot="main" title="Checkout" />
    <app-simple-button icon="chatbubbles-outline" (click)="help()" size="large" color="main" slot="end" label="Help" />
    <app-checkout-stepper (stepSelected)="navigateToStep($event)" [currentStep]="currentStepLabel()" />
    @if (selectedFulfillmentMethod()) {
      <app-countdown mode="cart" [methodId]="selectedFulfillmentMethod()!.selectedMethod.method" />
    }
  </app-header>
</ion-header>

<ion-content>
  <app-steps-container [step]="currentStep()">
    <!-- CART SECTION -->
    <div class="step cart">
      <!--Scripts-->
      <app-banner />
      <app-checkout-section (actionEvent)="addPrescriptions()" title="Prescriptions" [action]="true" actionLabel="Add">
        @for (merge of combinedPrescriptionItems(); track $index) {
          <app-prescription-card (removeItemEvent)="removeScript($event, merge.profile)" [items]="merge.items" [medicalProfile]="merge.profile" />
        } @empty {
          <app-prescriptions-empty (addPrescriptionEvent)="addPrescriptions()" />
        }
      </app-checkout-section>
      <!--OTC Items-->
      <app-checkout-section (actionEvent)="addOTCItems()" title="Over the counter products" [action]="true" actionLabel="Add">
        <div class="otc-list">
          @for (product of otcItems(); track $index) {
            <app-product-row [canEditQty]="true" (editQty)="editCartItem(product)" (remove)="removeItemFromCart($index)" [product]="product" [last]="$last" />
          } @empty {
            <app-otc-items-empty (addOTCItemEvent)="addOTCItems()" />
          }
        </div>
      </app-checkout-section>
    </div>

    <!-- SHIPPING SECTION -->
    <div class="step shipping">
       <!--Shipping Details-->
       <app-banner />
       <app-checkout-section title="Order address">
        @if (placeDetails()) {
          <app-address-details [canEdit]="true" [showEdit]="true" (editAddressEvent)="editAddress()" (editNotesEvent)="editDeliveryNotes()" [placeDetails]="placeDetails()!" />
        } @else {
          <app-button (click)="editAddress()" label="Set Address" size="large" />
        }

        @if(!doWeSplitOrder()) {
          <app-delivery-cart [count]="1" [multiOrder]="false" [type]="fulfillmentMethodToUse()?.selectedMethod?.method">
            <div slot="products">
              @for(merge of combinedPrescriptionItems(); track $index) {
                @for(item of merge.items; track $index) {
                  <app-prescription-row [item]="item" [simple]="true" [last]="$last" />
                }
              }
              @for(product of otcItems(); track $index) {
                <app-product-row  [product]="product" [simple]="true" [last]="$last" />
              }
            </div>
            <div slot="shipping">
              @if(placeDetails()) {
                @if(isPostalFulfillmentMethod() && doesCartHaveWeightLossScriptItems()) {
                   <!-- PART 1-->
                  <app-delivery-method-option  [pharmacyAddress]="pharmacyAddress()" [customerAddress]="placeDetails()" [methodSelected]="true" [fullFillmentMethod]="fulfillmentMethodToUse()" />
                } @else if (isPostalFulfillmentMethod() && !doesCartHaveWeightLossScriptItems()) {
                   <!-- PART 2-->
                  <app-delivery-method  (atlEvent)="setAtl($event)" [pharmacyAddress]="pharmacyAddress()" [customerAddress]="placeDetails()" (updateShiftForOnDemandEventDevMethod)="methodSelected($event, true,'PART 2 - updateShiftForOnDemandEventDevMethod')" (selectMethodEvent)="methodSelected($event,false,'PART 2 - selectMethodEvent')" location="cart" [advanced]="true" [orderFulfillmentMethods]="orderFulfillmentMethods()!" [prefFulfillmentMethod]="preferredMethodOnSession()" [selectedDeliveryMethod]="fulfillmentMethodToUse()" />
                } @else if (isWeightLossOnly()) {
                  <!-- PART 3 -->
                  <app-delivery-method-option  [pharmacyAddress]="pharmacyAddress()" [customerAddress]="placeDetails()" [methodSelected]="true" [fullFillmentMethod]="fulfillmentMethodToUse()" />
                } @else {
                  <!-- PART 4 -->
                  <app-delivery-method (atlEvent)="setAtl($event)" [pharmacyAddress]="pharmacyAddress()" [customerAddress]="placeDetails()" (updateShiftForOnDemandEventDevMethod)="methodSelected($event, true, 'PART 4 - updateShiftForOnDemandEventDevMethod')" (selectMethodEvent)="methodSelected($event,false, 'PART 4 - selectMethodEvent')" location="cart" [advanced]="true" [orderFulfillmentMethods]="orderFulfillmentMethods()!" [prefFulfillmentMethod]="preferredMethodOnSession()" [selectedDeliveryMethod]="fulfillmentMethodToUse()" />
                }
              }
            </div>  

            @if((isPostalFulfillmentMethod() && doesCartHaveWeightLossScriptItems()) || isWeightLossOnly()) {
              <div slot="extra">
                <div class="terms">
                  <div class="title">Shipping terms & conditions</div>
                  <div class="description">
                    Your order will be shipped following the relevant manufacturer's guidelines, which allow semaglutide to be stored for up to 42 days, and Mounjaro for up to 21 days, at room temperature (not exceeding 30°C).<br><br>To ensure that room temperature is not exceeded, we ship the medication in an insulated box with a cold pack inside. That way you have the peace of mind of knowing your medication has arrived in perfect condition.
                    @if(showHolidayMessage()) {
                      <div class="holiday-messsage">
                        Orders placed after December 19th will be dispatched 30th December. 
                      </div>
                    }
                  </div>
                  <app-checkbox [(ngModel)]="agreeToTempControlTerms" label="I accept shipping method" />
                </div>
              </div>

            }
          </app-delivery-cart>
        } @else {
          <app-delivery-cart [count]="1" [multiOrder]="true" [type]="fulfillmentMethodToUse()?.selectedMethod?.method">
            <div slot="products">
              @for(merge of combinedNonWeightLossPrescriptionItems(); track $index) {
                @for(item of merge.items; track $index) {
                  <app-prescription-row [item]="item" [simple]="true" [last]="$last" />
                }
              }
              @for(product of otcItems(); track $index) {
                <app-product-row  [product]="product" [simple]="true" [last]="$last" />
              }
            </div>
            @if (placeDetails()) {
              <div slot="shipping">
                <!-- PART 5 -->
                <app-delivery-method (atlEvent)="setAtl($event)"  [pharmacyAddress]="pharmacyAddress()" [customerAddress]="placeDetails()" (updateShiftForOnDemandEventDevMethod)="methodSelected($event,true,'PART 5 - updateShiftForOnDemandEventDevMethod')" location="cart" (selectMethodEvent)="methodSelected($event, false,'PART 5 - selectMethodEvent')" [advanced]="true" [orderFulfillmentMethods]="orderFulfillmentMethods()!" [prefFulfillmentMethod]="preferredMethodOnSession()" [selectedDeliveryMethod]="selectedFulfillmentMethod()" />
              </div>
            }
          </app-delivery-cart>
          <app-delivery-cart [count]="2" [multiOrder]="true" type="PostalTemperatureControlled">
            <div slot="products">
              @for(merge of combinedWeightLossPrescriptionItems(); track $index) {
                @for(item of merge.items; track $index) {
                  <app-prescription-row [item]="item" [simple]="true" [last]="$last" />
                }
              }
            </div>
            @if (placeDetails()) {
              <div slot="shipping">
                <app-delivery-method-option [methodSelected]="true" [fullFillmentMethod]="postalTemperatureControlledMethod" />
              </div>
            }

            <div slot="extra">
              <div class="terms">
                <div class="title">Shipping terms & conditions</div>
                <div class="description">
                  Your order will be shipped following the relevant manufacturer's guidelines, which allow semaglutide to be stored for up to 42 days, and Mounjaro for up to 21 days, at room temperature (not exceeding 30°C).<br><br>To ensure that room temperature is not exceeded, we ship the medication in an insulated box with a cold pack inside. That way you have the peace of mind of knowing your medication has arrived in perfect condition.
                  @if(showHolidayMessage()) {
                    <div class="holiday-messsage">
                      Orders placed after December 19th will be dispatched 30th December. 
                    </div>
                  }
                </div>
                <app-checkbox [(ngModel)]="agreeToTempControlTerms" label="I accept shipping method" />
              </div>
            </div>
          </app-delivery-cart>
        }

       </app-checkout-section>
    </div>

    <!-- PAYMENT SECTION -->
    <div class="step payment">
      <!--Payment Details-->
      <app-banner />
      <app-checkout-section title="Payment methods" [description]="labelForCheckoutDescription()">
        @if (paymentActive()) {
          <app-payment-wrapper (cardChange)="changeCard($event)" />
        }
        @if (!selectedCoupon()) {
          <app-coupon-row  [last]="true" (click)="selectCoupon()" />
        } @else {
          <app-promotion-row [noPadding]="true" [promotion]="selectedCoupon()" [last]="true" (clear)="clearCoupon()" [canClear]="true" />
        }
        
        <!-- @if (cartTotals()) {
          <app-cart-totals [cart]="cart()!" />
        } -->

        @if(cartTotalsList() && cartTotalsList()!.length) {
          <app-cart-totals [cart]="cart()!" />
        }

      </app-checkout-section>
    </div>
  </app-steps-container>
</ion-content>

<ion-footer>
  <app-footer>
    <!-- CART SECTION FOOTER BUTTON -->
    @if (cartActive()) {
      <app-button (clicked)="nextStep()" label="Proceed to shipping" iconRight="arrow-forward-outline" size="large" />
    }
    <!-- SHIPPING SECTION FOOTER BUTTON -->
    @if (shippingActive()) {
      <app-button  (clicked)="goToPayment()"  label="Proceed to payment" iconRight="arrow-forward-outline" size="large" />
    }
    <!-- PAYMENT SECTION FOOTER BUTTON -->
    @if (paymentActive()) {
      <app-button (clicked)="createOrder()" [disabled]="!(cardToUse() && placeDetails())" data-cy="cart-checkout-btn" [label]="labelForCheckoutButton()" size="large" />
    }
  </app-footer>
</ion-footer>
