import { inject, Injectable } from '@angular/core';
import { Subject, Observable, Subscription, timer, BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';
import { App } from '@capacitor/app';
import { StateService } from './state.service';
import { Order } from '@chemist2u/types-client/C2U/ParseObjects/index.client';
import { CloudFunctionsService } from './cloud.functions.service';
import { TFulfillmentMethodName, TOrderFulfillmentMethod, TOrderFulfillmentMethodClickAndCollect, TOrderFulfillmentMethodOnDemand, TOrderFulfillmentMethodStandard } from '@chemist2u/types-client/C2U/Interfaces';
import { PushService } from './push.service';
import { environment } from 'src/environments/environment';

interface TimerInfo {
  subscription: Subscription;
  endTime: number;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalTimingService {
  private $state = inject(StateService);
  private $cloud = inject(CloudFunctionsService);
  private $push = inject(PushService);
  private platform = inject(Platform);

  private readonly silentMode = !environment.production;

  // Timer Maps
  private fulfillmentTimers: Map<TFulfillmentMethodName, TimerInfo> = new Map();
  private orderTimers: Map<string, TimerInfo> = new Map();

  // Timer Expiration Maps
  private fulfillmentMethodEndTimeSubjects: Map<TFulfillmentMethodName, BehaviorSubject<number | undefined>> = new Map();
  private orderEndTimeSubjects: Map<string, BehaviorSubject<number | undefined>> = new Map();

  // Timer Expiration Subjects
  private fulfillmentTimerExpiredSubject = new Subject<string>();
  private orderTimerExpiredSubject = new Subject<string>();

  private log(...args: any[]) {
    if (this.silentMode) return;
    console.log("[GlobalTimingService]", ...args);
  }

  // --- State Management

  public init() {
    this.log('Constructor initialized.');

    this.listenToAppStateChanges();
    this.listenToExpirations();
    this.listenToStateChanges();

    this.initDefaultSubjects();
    this.initTimers();
  }

  private listenToAppStateChanges() {
    // Listen for app state changes
    this.platform.ready().then(() => {
      App.addListener('appStateChange', state => {
        if (state.isActive) {
          this.log('App is active; refreshing timers');
          this.destroyTimers();
          this.initTimers();
        } else {
          this.log('App is inactive; timers may pause');
          this.destroyTimers();
        }
      });
      this.log('App state change listener added');
    });
  }
  // TODO - URGENT - This is inneficient, we should cache the pharmacyBracketModel
  private listenToExpirations() {
    // Subscribe to fulfillment method expiration events
    this.fulfillmentTimerExpiredSubject.subscribe((methodId) => {
      this.log('Fulfillment method expired:', methodId);
      // Implement logic to handle expiration (e.g., refresh methods)
      const session = this.$state.bSession.getValue();
      if (session && session.address) {
        this.$state.updateSessionDetails(session);
        this.updateFulfillmentTimers();
      }
    });

    // Subscribe to order expiration events
    this.orderTimerExpiredSubject.subscribe(async (orderId) => {
      this.log('Order expired:', orderId);
      // Implement logic to handle order expiration (e.g., notify user)
      const order = this.$state.bOrders.getValue().find((order) => order.id === orderId);
      if (!order) return;
      // Implement logic to handle order expiration (e.g., notify user)
      this.log('Order expired:', order);
      // TODO - URGENT - This is inneficient, we should cache the pharmacyBracketModel
      const bAvailableFulfillmentMethods = order.shipping ? await this.$cloud.getOrderFulfillmentMethodsForPharmacy(order.shipping!) : [];
      this.log("bAvailableFulfillmentMethods", bAvailableFulfillmentMethods);
      const matchingFullfillmentMethod = bAvailableFulfillmentMethods.find((method) => method.selectedMethod.method === order.fulfillmentDetails?.selectedMethod.method);
      this.log("matchingFullfillmentMethod", matchingFullfillmentMethod);
      if (matchingFullfillmentMethod) {
        order.fulfillmentDetails = matchingFullfillmentMethod;
        await this.$push.order(order);
      }
    });
  }

  // TODO - URGENT - This is inneficient, we should cache the pharmacyBracketModel
  private listenToStateChanges() {
    // Subscribe to orders and session changes
    this.$state.bOrders.subscribe((orders: Order[]) => {
      this.log('Orders updated:', orders);
      this.updateOrderTimers(orders);
    });

    this.$state.bSession.subscribe((session) => {
      this.log('Session updated:', session);
      if (!session) return;
      this.$state.updateSessionDetails(session);
      this.updateFulfillmentTimers();
    });
  }

  private initDefaultSubjects() {
    // Initialize BehaviorSubjects for each methodId
    const methodIds: TFulfillmentMethodName[] = ['Standard', 'OnDemand', 'clickAndCollect']; // Adjust as needed
    methodIds.forEach((methodId) => {
      this.fulfillmentMethodEndTimeSubjects.set(methodId, new BehaviorSubject<number | undefined>(undefined));
    });
  }

  private initTimers(): void {
    this.log('Initializing timers');
    // Fetch initial data from $state service
    const initialOrders = this.$state.bOrders.getValue();
    const initialSession = this.$state.bSession.getValue();

    if (initialSession) {
      this.updateOrderTimers(initialOrders);
      this.updateFulfillmentTimers();
    }
  }
  
  private destroyTimers(): void {
    // Stop fulfillment method timers
    this.fulfillmentTimers.forEach((timerInfo, methodId) => {
      this.log(`Stopping timer for fulfillment method ${methodId}`);
      timerInfo.subscription.unsubscribe();
    });
    this.fulfillmentTimers.clear();

    // Stop order timers
    this.orderTimers.forEach((timerInfo, orderId) => {
      this.log(`Stopping timer for order ${orderId}`);
      timerInfo.subscription.unsubscribe();
    });
    this.orderTimers.clear();
  }

  // --- Fulfilment Method Timers

  private async updateFulfillmentTimers(): Promise<void> {
    this.log('Updating fulfillment timers');
    const fulfillmentMethods = this.$state.bAvailableFulfillmentMethods.getValue();

    // Get current method IDs
    const currentMethodIds = fulfillmentMethods.map((method) => method.selectedMethod.method);

    // Stop timers for methods that are no longer available
    this.fulfillmentTimers.forEach((timerInfo, methodId) => {
      if (currentMethodIds.includes(methodId)) return;
      // Method is no longer available; stop its timer
      this.log(`Stopping timer for fulfillment method ${methodId}`);
      timerInfo.subscription.unsubscribe();
      this.fulfillmentTimers.delete(methodId);

      // Emit null to indicate that the timer is no longer available
      this.fulfillmentMethodEndTimeSubjects.get(methodId)?.next(undefined);
    });

    // Start or update timers for current fulfillment methods
    fulfillmentMethods.forEach((method) => {
      const methodId = method.selectedMethod.method;
      const remainingTime = this.calculateRemainingTime(method);

      if (this.fulfillmentTimers.has(methodId)) {
        // Update existing timer if necessary
        const timerInfo = this.fulfillmentTimers.get(methodId);
        if (!timerInfo) return;
        const existingEndTime = timerInfo?.endTime;
        const newEndTime = Date.now() + remainingTime;

        if (Math.abs(existingEndTime - newEndTime) > 1000) {
          // Significant difference; restart timer
          this.log(`Restarting timer for fulfillment method ${methodId}`);
          timerInfo.subscription.unsubscribe();
          this.startFulfillmentTimer(methodId, remainingTime);
        }
      } else {
        // Start timer for new fulfillment method
        this.log(`Starting timer for fulfillment method ${methodId}, remaining time: ${remainingTime} ms`);
        if (remainingTime > 0) {
          this.startFulfillmentTimer(methodId, remainingTime);
        } else {
          // NOTE:
          // we are populating timers now,
          // if we just fetched shit that is expired,
          // when we fetch again it will also be expired
          // COMMENTING LINE UNTIL FURTHER FIX REALIZED
          // this.handleFulfillmentMethodExpired(methodId);
        }
      }
    });
  }

  private startFulfillmentTimer(methodId: TFulfillmentMethodName, duration: number): void {
    // Stop existing timer if any
    if (this.fulfillmentTimers.has(methodId)) {
      const existingTimerInfo = this.fulfillmentTimers.get(methodId);
      existingTimerInfo?.subscription.unsubscribe();
    }

    const endTime = Date.now() + duration;

    // Start new timer
    const timerSubscription = timer(duration).subscribe(() => {
      this.handleFulfillmentMethodExpired(methodId);
    });

    this.fulfillmentTimers.set(methodId, {
      subscription: timerSubscription,
      endTime: endTime,
    });

    // Update the BehaviorSubject with the new endTime
    this.fulfillmentMethodEndTimeSubjects.get(methodId)?.next(endTime);
  }

  private handleFulfillmentMethodExpired(methodId: TFulfillmentMethodName): void {
    this.log(`Fulfillment method ${methodId} has expired`);
    // Emit event
    this.fulfillmentTimerExpiredSubject.next(methodId);
    // Clean up timer
    if (this.fulfillmentTimers.has(methodId)) {
      this.fulfillmentTimers.get(methodId)?.subscription.unsubscribe();
      this.fulfillmentTimers.delete(methodId);
    }

    // Emit null to indicate that the timer is no longer available
    this.fulfillmentMethodEndTimeSubjects.get(methodId)?.next(undefined);
  }

  // --- Active Order Timers

  private updateOrderTimers(orders: Order[]): void {
    this.log('Updating order timers');
    // Get the list of orders that require timers
    const ordersWithTimers = orders.filter((order) => order.isApproved() && order.fulfillmentDetails);
    this.log('ordersWithTimers', ordersWithTimers);

    // Get current order IDs
    const currentOrderIds = ordersWithTimers.map((order) => order.id);

    // Stop timers for orders that no longer require timers
    this.orderTimers.forEach((timerInfo, orderId) => {
      if (currentOrderIds.includes(orderId)) return;
      
      // Order no longer requires a timer; stop its timer
      this.log(`Stopping timer for order ${orderId}`);
      timerInfo.subscription.unsubscribe();
      this.orderTimers.delete(orderId);

      // Emit undefined to indicate that the timer is no longer available
      this.orderEndTimeSubjects.get(orderId)?.next(undefined);
    });

    // Start timers for new orders that require timers
    ordersWithTimers.forEach((order) => {
      const orderId = order.id;
      if (this.orderTimers.has(orderId)) return;

      // Start timer for new order
      const remainingTime = this.calculateRemainingTime(order.fulfillmentDetails!);
      this.log(`Starting timer for order ${orderId}, remaining time: ${remainingTime} ms`);
      if (remainingTime > 0) {
        this.startOrderTimer(orderId, remainingTime);
      } else {
        // NOTE:
        // we are populating timers now,
        // if we just fetched shit that is expired,
        // when we fetch again it will also be expired
        // COMMENTING LINE UNTIL FURTHER FIX REALIZED
        // this.handleOrderExpired(orderId);
      }
    });
  }

  private startOrderTimer(orderId: string, duration: number): void {
    // Stop existing timer if any
    if (this.orderTimers.has(orderId)) {
      this.orderTimers.get(orderId)?.subscription.unsubscribe();
    }

    const endTime = Date.now() + duration;

    // Start new timer
    const timerSubscription = timer(duration).subscribe(() => {
      this.handleOrderExpired(orderId);
    });

    this.orderTimers.set(orderId, {
      subscription: timerSubscription,
      endTime,
    });

    // Update or create the BehaviorSubject with the new endTime
    if (this.orderEndTimeSubjects.has(orderId)) {
      this.orderEndTimeSubjects.get(orderId)?.next(endTime);
    } else {
      this.orderEndTimeSubjects.set(orderId, new BehaviorSubject<number | undefined>(endTime));
    }
  }

  private handleOrderExpired(orderId: string): void {
    this.log(`Order ${orderId} payment time has expired`);
    // Emit event
    this.orderTimerExpiredSubject.next(orderId);
    // Clean up timer
    if (this.orderTimers.has(orderId)) {
      this.orderTimers.get(orderId)?.subscription.unsubscribe();
      this.orderTimers.delete(orderId);
    }
    // Emit undefined to indicate that the timer is no longer available
    this.orderEndTimeSubjects.get(orderId)?.next(undefined);
  }

  // --- Time Functions

  // Calculate remaining time for a fulfillment method
  private calculateRemainingTime(method: TOrderFulfillmentMethod): number {
    // Implement actual calculation logic based on your business rules
    const cutoffTime = new Date(this.getCutoffTime(method)).getTime();
    const remainingTime = cutoffTime - Date.now();
    return remainingTime > 0 ? remainingTime : 0;
  }

  private getCutoffTime(method: TOrderFulfillmentMethod): number {
    if (method.selectedMethod.method === 'Standard') {
      const typedMethod = method as TOrderFulfillmentMethodStandard;
      const expectedDeliveryDate = typedMethod.expectedDeliveryDate!;
      const standardDeliveryCutoffMinutes = typedMethod.selectedMethod.standardDeliveryCutoffMinutes;
      const cutoffHours = Math.floor(standardDeliveryCutoffMinutes / 60);
      const cutoffMinutes = standardDeliveryCutoffMinutes % 60;
      const beforeDate = new Date(expectedDeliveryDate);
      beforeDate.setHours(cutoffHours, cutoffMinutes, 0, 0);
      return beforeDate.getTime();
    } else if (method.selectedMethod.method === 'OnDemand') {
      const typedMethod = method as TOrderFulfillmentMethodOnDemand;
      return typedMethod.allocatedShift?.cutoff.getTime() || 0;
    } else if (method.selectedMethod.method === 'clickAndCollect') {
      const typedMethod = method as TOrderFulfillmentMethodClickAndCollect;
      const expectedPickupDate = typedMethod.expectedPickupDate!;
      const clickAndCollectCutoffMinutes = typedMethod.clickAndCollectCutoffMinutes;
      const cutoffHours = Math.floor(clickAndCollectCutoffMinutes / 60);
      const cutoffMinutes = clickAndCollectCutoffMinutes % 60;
      const beforeDate = new Date(expectedPickupDate);
      beforeDate.setHours(cutoffHours, cutoffMinutes, 0, 0);
      return beforeDate.getTime();
    } else {
      // throw new Error('Invalid method');
      return -1;
    }
  }

  // --- Public Functions to Subcribe to Expirations

  public getFulfillmentMethodEndTimeObservable(methodId: TFulfillmentMethodName): Observable<number | undefined> | undefined {
    return this.fulfillmentMethodEndTimeSubjects.get(methodId)?.asObservable();
  }

  public getOrderEndTimeObservable(orderId: string): Observable<number | undefined> | undefined {
    if (this.orderEndTimeSubjects.has(orderId)) {
      return this.orderEndTimeSubjects.get(orderId)?.asObservable();
    } else {
      // If the BehaviorSubject doesn't exist yet, create one with undefined
      const subject = new BehaviorSubject<number | undefined>(undefined);
      this.orderEndTimeSubjects.set(orderId, subject);
      return subject.asObservable();
    }
  }
}