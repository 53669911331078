import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { addIcons } from 'ionicons';
import { IonIcon } from '@ionic/angular/standalone';
import { location, chevronForward } from 'ionicons/icons';
import { AddressType, PlaceAutocompleteResult } from '@googlemaps/google-maps-services-js';
import { TSessionAddress } from '@chemist2u/types-client/C2U/Interfaces';

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonIcon]
})
export class AddressDetailsComponent {

  placeDetails = input.required<TSessionAddress>();
  deliveryNoteEditMode = input<boolean>();
  showEdit = input<boolean>();
  canEdit = input<boolean>();
  fullEdit = input<boolean>();
  editAddressEvent = output<void>();
  editNotesEvent = output<void>();

  constructor() {
    addIcons({ location, chevronForward });
  }

  topLabel = computed(() =>  {
    const placeDetails = this.placeDetails();
    if (!placeDetails) return "";
    const addressComponents = placeDetails.address.address_components;
    const streetNumber = addressComponents.find(comp => comp.types.includes(AddressType.street_number))?.long_name || '';
    const route = addressComponents.find(comp => comp.types.includes(AddressType.route))?.long_name || '';
    const aptSuiteFloor = placeDetails.aptSuiteFloor;

    if (aptSuiteFloor) {
      return `${aptSuiteFloor}/${streetNumber} ${route}`;
    } else {
      return (placeDetails.selectedAddress as PlaceAutocompleteResult).structured_formatting.main_text;
    }
  });

  bottomLabel = computed(() => (this.placeDetails().selectedAddress as PlaceAutocompleteResult).structured_formatting.secondary_text);

  hasDeliveryInstructions = computed(() => {
    return this.placeDetails().deliveryNote !== undefined && this.placeDetails().deliveryNote !== null;
  });

  deliveryInstructions = computed(() => {
    return this.placeDetails().deliveryNote;
  });

  editAddress() {
    if(!this.canEdit) return;
    this.editAddressEvent.emit();
  }

  editNotes() {
    if(!this.canEdit) return;
    this.editNotesEvent.emit();
  }

}